import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./utility/Dashboard"
import Homepage from "./pages/Homepage"
import NotFound from "./utility/NotFound"
import PrivateRoute from "./utility/PrivateRoute"
import Login from './pages/auth/Login'
import NotAllowed from './utility/NotAllowed'
import Bills from "./pages/bills/Bills";
import NewPerformaInvoice from "./pages/performaInvoices/NewPerformaInvoice";
import EditPerformaInvoice from "./pages/performaInvoices/EditPerformaInvoice";
import NewBill from "./pages/bills/NewBill";
import EditBill from "./pages/bills/EditBill";
import PrintInvoice from "./pages/performaInvoices/PrintInvoice";
import PrintBill from "./pages/bills/PrintBill";

function App() {

  return (
    <Router>
      <Routes>
      <Route element={<PrivateRoute/>}>
          {/* Routes with dashbaord layout */}
          <Route element={<Dashboard/>}>
            {/* PI */}
            <Route exact path='/' element={<Homepage />} />
            <Route exact path='/performaInvoice/new' element={<NewPerformaInvoice />} />
            <Route exact path='/performaInvoice/:id' element={<EditPerformaInvoice />} />
            {/* Bills */}
            <Route exact path='/bills' element={<Bills />} />
            <Route exact path='/bill/new' element={<NewBill />} />
            <Route exact path='/bill/:id' element={<EditBill />} />
          </Route>
            <Route exact path='/bill/print/:id' element={<PrintBill />} />
            <Route exact path='/performaInvoice/print/:id' element={<PrintInvoice />} />
        </Route>
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/notallowed' element={<NotAllowed />} />
        <Route exact path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
