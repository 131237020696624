import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {login} from "../../redux/actions/userAction"
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';

const Login = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  // error states
  const [errorMessage,setErrorMessage]=useState("")

  const handelLogin = () => {
    if(phone===undefined || password.trim().length===0){
      setErrorMessage("Please fill all the fields")
    } else {
      setLoading(true)
      dispatch(login(phone, password))
      .then(res=>{
        if (!res.success) {
          setLoading(false)
          setErrorMessage(res.message)
        } else {
          if (res.user.role === "Admin") {
            const user = {name: res.user.name, phone: res.user.phone, id: res.user._id}
            localStorage.setItem("user", JSON.stringify(user))
            localStorage.setItem("token", res.token)
            navigate('/')
          } else {
            navigate('/notallowed')
          }
        }
      })
    }
  }

useEffect(() => {
  const listener = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      document.getElementById("submitBtn").click()
    }
  };
  document.addEventListener("keydown", listener);
  return () => {
    document.removeEventListener("keydown", listener);
  };
}, [])

  return (
      <section className='d-flex align-items-center justify-content-center vh-100'>
      <main className="form-signin container-fluid" style={{width:"100vw", maxWidth:"450px"}}>
        <img className="mb-4 text-primary" src="/assets/images/logo/logo-login.svg" alt="" width="100"/>
        <div>
          <TextField value={phone} onChange={(e)=> setPhone(e.target.value)} label="Phone Number" variant="outlined"  type="number" className="w-100" />
          <TextField value={password} onChange={(e)=> setPassword(e.target.value)} label="Password" variant="outlined"  type="password" className="w-100 mt-3" />
        </div>
        <button id='submitBtn' onClick={handelLogin} className="w-100 btn btn-lg shadow btn-tertiary mt-3 flex-center">{loading ? <div className="spinner-border text-white fs-6 flex-center" role="status"><span className="sr-only"></span></div>:'Login'}</button>
        {errorMessage && <p className='text-danger my-4 text-center'>{errorMessage}</p>}
      </main>
     </section>
  )
}

export default Login