import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {getPerformaInvoiceDetails, updatePerformaInvoice} from '../../redux/actions/performaInvoiceAction'
import moment from 'moment'

import Loader from '../../utility/Loader'
import BillToSupplyTo from '../../components/BillingSystem/BillToSupplyTo'
import HeadingAndDate from '../../components/BillingSystem/HeadingAndDate'
import ItemsHandler from '../../components/BillingSystem/ItemsHandler'
import TotalAndSave from '../../components/BillingSystem/TotalAndSave'

const EditPerformaInvoice = () => {
    
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {performaInvoices} = useSelector(state=>state.performaInvoices)

    const [buttonLoading, setButtonLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    
    const [date, setDate] = useState(moment())
    const [items, setItems] = useState([])
    const [billTo, setBillTo] = useState({name:"", gst: "", address: ""})
    const [placeOfSupply, setPlaceOfSupply] = useState({name:"", gst: "", address: ""})
    const [subTotalAmount, setSubTotalAmount] = useState(0)
    const [taxAmount, setTaxAmount] = useState()
    const [totalAmount, setTotalAmount] = useState()
    const [taxCategory, setTaxCategory] = useState("IN");
    const [invoiceConverted, setInvoiceConverted] = useState(false)
    const [sameAsBillTo, setSameAsBillTo] = useState(true);

    useEffect(() => {
      const isPerformaInvoiceExist = performaInvoices.find((i) => i._id === id)
      if (isPerformaInvoiceExist) {
          setStatesForDuplication(isPerformaInvoiceExist)
      } else {
          dispatch(getPerformaInvoiceDetails(id)).then((res) => {
              if (res.success) {
                  setStatesForDuplication(res.performaInvoice)
              }
          })
      }
    }, [])

    const setStatesForDuplication = (details) => {
      setDate(details.createdAt)
      setItems(details.items)
      setBillTo(details.billTo)
      setPlaceOfSupply(details.placeOfSupply)
      setSameAsBillTo(checkforSameAsBillTo(details))
      setSubTotalAmount(details.subTotalAmount)
      setTaxAmount(details.taxAmount)
      setTotalAmount(details.totalAmount)
      setTaxCategory(details.taxCategory)
      setInvoiceConverted(details.invoiceStatus === "converted")
      setLoading(false)
    }

    const checkforSameAsBillTo = (details) => {
      let same = false
      if (details.billTo.name === details.placeOfSupply.name && details.billTo.address === details.placeOfSupply.address && details.billTo.gst === details.placeOfSupply.gst) {
        same = true
      } else {
        same = false
      }
      return same
    }

    const handleSubmit = (action) => {
      setButtonLoading(true)
      const data = {
        createdAt: JSON.stringify(date).substring(1, JSON.stringify(date).length -1),
        billTo: billTo,
        placeOfSupply: sameAsBillTo ? billTo : placeOfSupply,
        items: items,
        subTotalAmount: subTotalAmount,
        taxAmount: taxAmount,
        totalAmount: totalAmount,
        taxCategory: taxCategory,
        invoiceStatus: invoiceConverted ? "converted" : "notConverted",
      }
      dispatch(updatePerformaInvoice(id, data))
      .then(res => {
        if (res.success) {
          action === "saveAndPrint" ? navigate(`/performaInvoice/print/${res.performaInvoice._id}?print=true`) : navigate('/')
        } else {
          setError(res.message)
        }
        setButtonLoading(false)
      })
    }

    useEffect(() => {
      if (items.length>0) {
          let amount = 0
          for (let i = 0; i < items.length; i++) {
            const itemTotalAmount = items[i].quantity * items[i].price;
            amount = amount + itemTotalAmount
          }
          setSubTotalAmount(amount)
      }
    }, [items])

    useEffect(() => {
      setTaxAmount(subTotalAmount*18/100)
      setTotalAmount(subTotalAmount + subTotalAmount*18/100)
    }, [subTotalAmount]);

  return loading ? (<Loader/>) : 
    (
      <section className='py-5 text-primary'>
      <div className="container">
        <HeadingAndDate heading={"Edit PI"} date={date} setDate={setDate} />
        <BillToSupplyTo billTo={billTo} setBillTo={setBillTo} placeOfSupply={placeOfSupply} setPlaceOfSupply={setPlaceOfSupply} sameAsBillTo={sameAsBillTo} setSameAsBillTo={setSameAsBillTo} />
        <ItemsHandler items={items} setItems={setItems} />
        <TotalAndSave taxCategory={taxCategory} setTaxCategory={setTaxCategory} subTotalAmount={subTotalAmount} totalAmount={totalAmount} buttonLoading={buttonLoading} handleSubmit={handleSubmit} />
        <p className="text-danger mt-3">{error}</p>
      </div>
    </section>
    )
}

export default EditPerformaInvoice