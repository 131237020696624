import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBillDetails } from '../../redux/actions/billActions'
import Loader from '../../utility/Loader'
import PrintHandler from '../../components/BillingSystem/PrintHandler'

const PrintBill = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {bills} = useSelector(state=>state.bills)

    const [bill, setBill] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const isBillExist = bills.find((i) => i._id === id)
        if (isBillExist) {
            setBill(isBillExist)
            setLoading(false)
        } else {
            dispatch(getBillDetails(id)).then((res) => {
                if (res.success) {
                    setBill(res.bill)
                    setLoading(false)
                }
            })
        }
    }, [])

  return loading ? (<Loader/>) : 
  (
    <PrintHandler details={bill} type={"bill"} />
  )
}

export default PrintBill