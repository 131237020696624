import React from 'react'
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const BillToSupplyTo = ({billTo, setBillTo, sameAsBillTo, setSameAsBillTo, placeOfSupply, setPlaceOfSupply}) => {
  return (
    <div>
        {/* Bill To */}
        <div className="row mt-4">
            <h5 className='fw-bold'>Bill To</h5>
            <div className="col-12 col-lg-6">
              <TextField label="Name" variant="outlined" className="w-100" autoComplete="off" value={billTo.name} onChange={(e)=>setBillTo({...billTo, name: e.target.value})} />
              <TextField label="GST" variant="outlined" className="w-100 mt-3" autoComplete="off" value={billTo.gst} onChange={(e)=>setBillTo({...billTo, gst: e.target.value})} />
            </div>
            <div className="col-12 col-lg-6 mt-3 mt-lg-0">
              <TextField label="Address" variant="outlined" className="w-100 h-100" multiline rows={4} value={billTo.address} onChange={(e)=>setBillTo({...billTo, address: e.target.value})} />
            </div>
          </div>
          {/* Place Of Supply */}
          <div className="mt-4">
            <h5 className='fw-bold'>Place Of Supply - <FormControlLabel control={<Checkbox checked={sameAsBillTo} onChange={(e)=>{setSameAsBillTo(!sameAsBillTo)}} />} label="(Same As Bill To)" /></h5>
            {!sameAsBillTo && <div className="row">
              <div className="col-12 col-lg-6">
                <TextField label="Name" variant="outlined" className="w-100" autoComplete="off" value={placeOfSupply.name} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, name: e.target.value})} />
                <TextField label="GST" variant="outlined" className="w-100 mt-3" autoComplete="off" value={placeOfSupply.gst} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, gst: e.target.value})} />
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                <TextField label="Address" variant="outlined" className="w-100 h-100" multiline rows={4} value={placeOfSupply.address} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, address: e.target.value})} />
              </div>
            </div>}
          </div>
    </div>
  )
}

export default BillToSupplyTo