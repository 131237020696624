import React from 'react'
import PerformaInvoices from './performaInvoices/performaInvoices'

const Homepage = () => {

  return (
    <PerformaInvoices/>
  )
}

export default Homepage