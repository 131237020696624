import React from 'react'
import { menuIcon } from './svg'
import { useNavigate } from 'react-router-dom'


const Navbar = () => {

    const navigate = useNavigate()
  
    const handleLogout = () => {
      localStorage.clear()
      navigate('/login')
    }

    const handleLink = (link) => {
        document.getElementById("navCloseBtn").click()
        navigate(link)
    }

return (
    <nav className="navbar">
        <div className="container">
            <a className="navbar-brand" href="/"><img className='nav-logo'
                    src="/assets/images/logo/logo-navbar-tertiary.svg" alt={`Geld Tech Sensor`} /></a>
            <button className="navbar-toggler-new" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                {menuIcon}
            </button>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><img className='navbar-canvas-logo'
                            src="/assets/images/logo/logo-navbar-dark.svg" alt={`Geld Tech Sensor`} /></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" id='navCloseBtn'></button>
                </div>
                <div className="offcanvas-body d-flex flex-column justify-content-between">
                    <ul className="navbar-nav pe-3">
                        <li className="nav-item d-flex flex-column">
                            <span className="nav-link underline cursor-pointer" aria-current="page" onClick={()=>{handleLink('/')}}>Performa Invoice</span>
                            <a className="nav-link underline cursor-pointer" aria-current="page" href={`/bills`}>Tax Invoice</a>
                            <a className="nav-link underline cursor-pointer" aria-current="page" href={`/bills?financialTerm=2324`}>Tax Invoice (2324)</a>
                            <span onClick={handleLogout} className="nav-link underline cursor-pointer text-danger" aria-current="page" to="/">Log Out</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
)
}

export default Navbar