import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getPerformaInvoices } from '../../redux/actions/performaInvoiceAction'
import moment from 'moment/moment'
import Loader from '../../utility/Loader'
import {convertedIcon, notConvertedIcon, copyIcon, editIcon, deleteIcon, convertedToBill, convertToBill} from '../../utility/svg'
import Tooltip from '@mui/material/Tooltip';
import DeleteHandler from '../../components/BillingSystem/DeleteHandler'
import DuplicateHandler from '../../components/BillingSystem/DuplicateHandler'
import ConvertHandler from '../../components/BillingSystem/ConvertHandler'

const PerformaInvoices = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {performaInvoices, loading} = useSelector(state=>state.performaInvoices)
    const [selectedPerformaInvoiceId, setSelectedPerformaInvoiceId] = useState()


    useEffect(() => {
        if (performaInvoices.length===0) {
            dispatch(getPerformaInvoices())
          }
    }, [])

  const handleConvert = (id) => {
    setSelectedPerformaInvoiceId(id)
    document.getElementById("openConvertHandlerModal").click()
  }

  const handleDuplicate = (id) => {
    setSelectedPerformaInvoiceId(id)
    document.getElementById("openDuplicateHandlerModal").click()
  }

  const handleDelete = (id) => {
    setSelectedPerformaInvoiceId(id)
    document.getElementById("openDeleteHandlerModal").click()
  }

  const navigateToViewPerformaInvoice = (id) => (
    navigate(`/performaInvoice/print/${id}`)
  )

  return loading ? (<Loader/>) : (
    <section className='py-5 min-vh-100'>
      <div className="container">
        <div className="row">
          <div className="col-6 flex-center"><Link to={'/'} className='h2 text-primary text-center fw-bold text-underline'>Performa <span className='d-none d-lg-inline'>Invoice</span></Link></div>
          <div className="col-6 flex-center"><Link to={'/bills'} className='h2 text-primary text-center fw-bold underline opacity-50'>Tax Invoice</Link></div>
        </div>
      </div>
      <div className="container">
        <Link className='btn btn-tertiary mt-4' to={'/performaInvoice/new'}>Create New PI</Link>
        {performaInvoices && performaInvoices.map((item, index) => (
            <div key={index}
              className="d-flex flex-column flex-lg-row align-items-start justify-content-start align-items-lg-center justify-content-lg-between flex-wrap py-3 gap-3">
              <div className="flex-grow-1 w-100 w-lg-auto">
                <div onClick={()=>navigateToViewPerformaInvoice(item._id)} className={`cursor-pointer transition-hover shadow d-flex flex-wrap align-items-start justify-content-between text-white p-3 rounded-2 fw-regular ${index%2===0 ? 'bg-primary' : 'bg-primary-2'}`}>
                  <div className='d-flex flex-column flex-lg-row'>
                    <div className="me-2">{`PI-${item.taxYear}-${item.invoiceNumber}`}</div>
                    <div className="fw-bold">{item.billTo.name}</div>
                  </div>
                  <div>
                    {moment(item.createdAt).format('D MMM YY')}
                  </div>
                </div>
              </div>
              <div className='flex-center h-100'>
                {/* <span className='shadow me-3 cursor-pointer transition-hover'>{item.invoiceStatus === "converted" ? convertedIcon : notConvertedIcon}</span>
                <span className='shadow me-3 cursor-pointer transition-hover' onClick={()=>handleDuplicate(item._id)}>{copyIcon}</span>
                <Link className='shadow me-3 cursor-pointer transition-hover' to={`/performaInvoice/${item._id}`}>{editIcon}</Link>
                <span className='shadow cursor-pointer transition-hover' onClick={()=>handleDelete(item._id)}>{deleteIcon}</span> */}

                <Tooltip title="Convert To Bill" placement="top"><span className='shadow me-3 cursor-pointer transition-hover' onClick={()=>handleConvert(item._id)}>{item.invoiceStatus === "converted" ? convertedToBill : convertToBill}</span></Tooltip>
                <Tooltip title="Duplicate PI" placement="top"><span className='shadow me-3 cursor-pointer transition-hover' onClick={()=>handleDuplicate(item._id)}>{copyIcon}</span></Tooltip>
                <Tooltip title="Edit PI" placement="top"><Link className='shadow me-3 cursor-pointer transition-hover' to={`/performaInvoice/${item._id}`}>{editIcon}</Link></Tooltip>
                <Tooltip title="Delete PI" placement="top"><span className='shadow cursor-pointer transition-hover' onClick={()=>handleDelete(item._id)}>{deleteIcon}</span></Tooltip>

                <DeleteHandler type={"performaInvoice"} id={selectedPerformaInvoiceId} />
                <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#deleteHandler" id='openDeleteHandlerModal'>Delete Handler</button>
                <DuplicateHandler type={"performaInvoice"} id={selectedPerformaInvoiceId} />
                <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#duplicateHandler" id='openDuplicateHandlerModal'>Duplicate Handler</button>
                <ConvertHandler type={"performaInvoice"} id={selectedPerformaInvoiceId} />
                <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#convertHandler" id='openConvertHandlerModal'>Convert Handler</button>
              </div>
            </div>
        ))}
      </div>

    </section>
  )
}

export default PerformaInvoices