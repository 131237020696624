import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPerformaInvoiceDetails } from '../../redux/actions/performaInvoiceAction'
import Loader from '../../utility/Loader'
import PrintHandler from '../../components/BillingSystem/PrintHandler'

const PrintInvoice = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {performaInvoices} = useSelector(state=>state.performaInvoices)

    const [performaInvoice, setPerformaInvoice] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const isPerformaInvoiceExist = performaInvoices.find((i) => i._id === id)
        if (isPerformaInvoiceExist) {
            setPerformaInvoice(isPerformaInvoiceExist)
            setLoading(false)
        } else {
            dispatch(getPerformaInvoiceDetails(id)).then((res) => {
                if (res.success) {
                    setPerformaInvoice(res.performaInvoice)
                    setLoading(false)
                }
            })
        }
    }, [])

  return loading ? (<Loader/>) : 
  (
    <PrintHandler details={performaInvoice} type={"performaInvoice"} />
  )
}

export default PrintInvoice