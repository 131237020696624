import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

const HeadingAndDate = ({date, setDate, heading}) => {
  return (
    <div>
        {/* Heading & Date */}
        <div className="row">
            <div className="col"><h2 className='fw-bold'>{heading}</h2></div>
            <div className="col d-flex align-items-end justify-content-end">
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                  <MobileDatePicker
                      value={date}
                      onChange={(newValue) => {
                          setDate(newValue);
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                          <>
                            <h4 className='text-end text-underline fw-bold cursor-pointer' ref={inputRef} {...inputProps}>{moment(date).format('D MMM YYYY')}</h4>
                            {InputProps?.endAdornment}
                          </>
                      )}
                  />
              </LocalizationProvider>
            </div>
          </div>
    </div>
  )
}

export default HeadingAndDate