import React from 'react'
import TextField from '@mui/material/TextField';
import { geldProducts } from '../../utility/products';
import Modal from '../utility/Modal';

const ItemsHandler = ({items, setItems}) => {

    const handleItemDetailNameChange = (e, item) => {
        setItems(items.map((i) => (
          i.randomId === item.randomId
            ? { ...item, name: e.target.value }
            : i
        )))
      }
    
      const handleItemDetailHsnChange = (e, item) => {
        setItems(items.map((i) => (
          i.randomId === item.randomId
            ? { ...item, hsn: e.target.value}
            : i
        )))
        }
  
      const handleItemDetailQuantityChange = (e, item) => {
          setItems(items.map((i) => (
            i.randomId === item.randomId
              ? { ...item, quantity: e.target.value, totalAmount: item.price * e.target.value }
              : i
          )))
          }
        
      const handleItemDetailPriceChange = (e, item) => {
        setItems(items.map((i) => (
          i.randomId === item.randomId
            ? { ...item, price: e.target.value, totalAmount: e.target.value * item.quantity }
            : i
        )))
        }
  
      const handleDeleteItem = (item) => {
        const newItems = items.filter((i)=>i.randomId !== item.randomId)
        setItems(newItems)
      }

    // Handling Addition Of New Item
    
    const openAddNewItemModal = (id) => {
        document.getElementById("openAddItemModal").click()
        }
  
      const handleAddNewItem = (item) => {
        const newItem = {image:item.image[0], name:item.name, hsn: item.hsn, quantity: '', price: '', randomId: Math.random()}
        setItems([...items, newItem])
        items.push()
        closeModal()
      }
  
      const closeModal = () => {
          document.getElementById("closeAddItemModal").click()
      }
  
      const miscItemHandler = (type) => {
        let  newItem = {image:"", name:"", hsn: "", quantity: '', price: '', randomId: Math.random()}
        if (type==="shipping") {
          newItem = {image:"/assets/images/shipping.jpg", name:"Shipping Charges", hsn: "", quantity: '1', price: '', randomId: Math.random()}
        }
        setItems([...items, newItem])
        items.push()
      }

      
  return (
    <div>
        {/* Items */}
        <div className="mt-4">
            <h5 className='fw-bold mb-2'>Items</h5>
            {items && items.map((item, index)=>(
                <div key={index} className="row flex-center justify-content-lg-center justify-content-start mb-2 mb-lg-0">
                  <div className="col-2 col-lg-1 mb-3 pe-0 pe-lg-3"><img className='new-bill-item-image' src={item.image} alt="" /></div>
                  <div className="col-10 col-lg-4 mb-3"><TextField value={item.name} onChange={(e)=>handleItemDetailNameChange(e, item)} label="Item Name" variant="outlined" className="w-100" autoComplete="off" /></div>
                  <div className="col-4 col-lg-2 mb-3"><TextField value={item.hsn} onChange={(e)=>handleItemDetailHsnChange(e, item)} label="HSN" variant="outlined" className="w-100" autoComplete="off" type="number" /></div>
                  <div className="col-4 col-lg-1 mb-3"><TextField value={item.quantity} onChange={(e)=>handleItemDetailQuantityChange(e, item)} label="Qty" variant="outlined" className="w-100" autoComplete="off" type="number" /></div>
                  <div className="col-4 col-lg-1 mb-3"><TextField value={item.price} onChange={(e)=>handleItemDetailPriceChange(e, item)} label="Price" variant="outlined" className="w-100" autoComplete="off" type="number" /></div>
                  <div className="col-10 col-lg-2 mb-3 pe-lg-0"><TextField value={item.quantity * item.price} label="Amount" variant="outlined" className="w-100 new-bill-item-totalAmount" autoComplete="off" type="number" disabled /></div>
                  <div className="col-2 col-lg-1 mb-3 d-flex justify-content-end"><img onClick={(e)=>{handleDeleteItem(item)}} className='cursor-pointer' src="/assets/images/icons/delete.svg" alt="" /></div>
                </div>
            ))}
            <div className='d-flex flex-wrap gap-2'>
              <button className='btn btn-warning text-white w-auto' onClick={openAddNewItemModal}>Add New Product</button>
              <button className='btn btn-warning text-white w-auto' onClick={()=>miscItemHandler("shipping")}>Add Shipping</button>
              <button className='btn btn-warning text-white w-auto' onClick={()=>miscItemHandler()}>Add Empty Product</button>
            </div>
        </div>
        {/* <AddItemModal/> */}
        <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#addItem" id='openAddItemModal'>Add Item</button>
        <Modal targetName={"addItem"} modalSettings={"modal-dialog-scrollable modal-xl"}>
                <div className='p-lg-5 p-1 text-center'>
                    <h1>Select Item</h1>
                    <div className="row">
                        {geldProducts.map((item, index) => (
                        <div key={index} className="col-lg-3 col-6 category-card cursor-pointer">
                        <span onClick={(e)=>{handleAddNewItem(item)}}>
                            <img src={item.image[0]} alt={`${item.name} - Geld Tech Sensor`} loading='lazy' />
                            <p className='text-center fw-bold'>{item.name}</p>
                        </span>
                        </div>
                        ))}
                    </div>
                    <div className="row flex-center">
                        <div className="col-12">
                            <button className="btn btn-primary shadow w-100 fs-4 flex-center" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                </div>
                <button className='d-none' data-bs-dismiss="modal" id="closeAddItemModal">Close modal</button>
        </Modal>
    </div>
  )
}

export default ItemsHandler