import React from 'react'
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const TotalAndSave = ({taxCategory, setTaxCategory, subTotalAmount, totalAmount, buttonLoading, handleSubmit}) => {
  return (
    <div>
        {/* Total & Save */}
        <div className="row mt-4">
              <div className="col-12 col-lg-4 d-flex justify-content-center align-items-end flex-column order-2 order-lg-2">
                <FormControlLabel control={<Radio />} className="tax-category-label" checked={taxCategory === "IN"} value="IN" onChange={(e)=>{setTaxCategory("IN")}} label="India - IGST (18%)" labelPlacement="start" />
                <FormControlLabel control={<Radio />} className="tax-category-label" checked={taxCategory === "DEL"} value="DEL" onChange={(e)=>{setTaxCategory("DEL")}} label="Delhi - CGST (9%) + SGST (9%) " labelPlacement="start" />
              </div>
              <div className="col-12 col-lg-4 text-white order-2 order-lg-3">
                    {/* Subtotal and Tax */}
                    <div className="bg-tertiary p-3">
                    <p className='flex-between mb-1'><span>Sub-Total</span><span className='fw-semibold'>Rs. {parseFloat(subTotalAmount).toFixed(2)}</span></p>
                    {taxCategory === "IN" ? <p className='flex-between mb-1'><span>IGST (18%)</span><span className='fw-semibold'>Rs. {parseFloat(subTotalAmount *18/100).toFixed(2)}</span></p> : 
                        <>
                        <p className='flex-between mb-1'><span>CGST (9%)</span><span className='fw-semibold'>Rs. {parseFloat(subTotalAmount *9/100).toFixed(2)}</span></p>
                        <p className='flex-between mb-0'><span>SGST (9%)</span><span className='fw-semibold'>Rs. {parseFloat(subTotalAmount *9/100).toFixed(2)}</span></p>
                        </>
                    }
                    </div>
                    <div className="bg-primary p-3">
                    <h4 className='fw-bold flex-between mb-0'><span>Grand Total</span><span>Rs. {Math.trunc(totalAmount)}</span></h4>
                    </div>
                </div>
              <div className="col-12 col-lg-4 d-flex flex-column justify-content-end align-items-start order-3 order-lg-1 my-3 my-lg-0">
                <button className='btn btn-tertiary btn-lg d-flex' onClick={()=>handleSubmit("save")}><i className="bi bi-floppy pe-2"></i> {buttonLoading ? <div className="spinner-border fs-6 button-spinner flex-center" role="status"><span className="visually-hidden">Loading...</span></div> : "Save"}</button>
                <button className='btn btn-primary btn-lg mt-3 d-flex' onClick={()=>handleSubmit("saveAndPrint")}><i className="bi bi-download pe-2"></i> {buttonLoading ? <div className="spinner-border fs-6 button-spinner flex-center" role="status"><span className="visually-hidden">Loading...</span></div> : "Save & Download"}</button>
              </div>
          </div>
        </div>
  )
}

export default TotalAndSave