export const geldProducts = [
    {
        handle: "360degree-pir-motion-sensor",
        name: "360° PIR MOTION SENSOR",
        image: [
            "/assets/images/products/1.png",
            "/assets/images/products/2.png",
            "/assets/images/products/3.png",
            "/assets/images/products/4.png",
            "/assets/images/products/5.png",
        ],
        model: "GP-01",
        description: "Geld Tech Presents our fully automated Passive Infrared (PIR) Sensor with 360 degrees field of view. Our PIR Sensor is fully equipped with safety, saving-energy and practical functions. It can detect human body presence and performdifferent type of functions like automatic light on when it detects human presence and off after a time slice of non-presence. It automatically distinguishes between day and night, but we can opt only night mode as per requirement.",
        metaDescription:"PIR Motion sensor 360 digree - Best 360 Degree PIR Motion Sensor Supplier, PIR Sensor Supplier, Motion Sensor Suppliers In Delhi, India",
        keyword: "PIR Motion sensor 360 digree, Geld Tech Sensor, 360 Degree PIR Motion Sensor Supplier, Motion Sensor Supplier, PIR Sensor Supplier",
        metaDescription:"PIR Motion sensor 360 digree - Best 360 Degree PIR Motion Sensor Supplier, PIR Sensor Supplier, Motion Sensor Suppliers In Delhi, India",
        keyword: "",
        specification: [
            "Power Source : 220- 240V/AC",
            "Power Frequency : 50/60Hz",
            "Ambient Light : < 3- 2000 LUX (adjustable)",
            "Time Delay: Min. 10sec ± 3sec Max 30min ± 2min (adjustable) ",
            "Rated Load : Max.1200W - 300W",
            "Detection Range: 360° ",
            "Detection Distance : Max. 6m ( < 24° C ) adjustable ",
            "Installation Height: 2.2 - 4 m",
        ],
        features: [
            "Stand Alone 220V AC Operation",
            "LUX Level Control & Time Adjustment",
            "Retrigger Funtion",
        ],
        application: [
            "Main Entrance ",
            "Toilet",
            "Pantry",
            "Stairways",
            "Lifts",
            "Lobby",
            "Covered Parking Area",
            "Lockers Room ",
            "Trial Rooms ",
            "Office ",
            "ATM",
            "Malls",
        ],
        category:"pir",
        hsn: 90329000,
    },
    {
        handle: "360degree-ceiling-mount-sensor-slim-model",
        name: "360° PIR CEILING MOUNT SENSOR (SLIM MODEL)",
        image: [
            "/assets/images/products/8.png",
            "/assets/images/products/9.png",
            "/assets/images/products/10.png",
            "/assets/images/products/11.png",
            "/assets/images/products/12.png",
        ],
        model: "GP-02",
        description: "Geld Tech Presents, Ceiling Mounted Sensor, which able to differentiate Night and day, allowing it to work efficiently and save energy. Using the Infrared sensors built into it, this sensor can detect human body presence and perform different types of functions like automatic light on when it detects human presence and off after a time slice of non presence. It also detects day & night time for its functionality. It is available in a slim & attractive look and easily mount at the ceiling.It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Ceiling Mount Sensor - Ceiling Mount Sensor Supplier, PIR Based Sensor Supplier, Sensor for Toilets Suppier, Lobby and Stairways Supplier In Delhi, India",
        keyword: "Ceiling Mount Sensor, Geld Tech Sensor, Ceiling Mount Sensor Supplier, PIR Based Sensor Supplier, Sensor for Toilets Suppier, Loddy and Stairways Supplier",
        specification: [
            "Power Source: 220-240 V/AC ",
            "Power Frequency: 50/60Hz",
            "Ambient Light: < 3- 2000LUX ( adjustable) ",
            "Time Delay: Min.10sec ± 3sec Max 15min ± 2 min ",
            "Rated Load: Max 800W Min 400W ",
            "Detection Range: 360° ",
            "Detection Distance: 8m max (< 24°C) ",
            "Installation Height: 2. 2 - 4m ",
            "Detection Moving Speed: 0.6 - 1.5m/s",
        ],
        application: [
            "Pantry ",
            "Toilet",
            "Lobby",
            "Office",
            "ATM",
            "Lift",
            "Trial Room",
            "Mall",
            "Stairways",
            "Basement",
            "Main Entrance ",
            "Corridor",
            "Covered Parking Area ",
            "Hospital",
            "Garage and Many More..",
        ],
        category:"pir",
        hsn: 90329000,
    },
    {
        handle: "pir-motion-sensor-on-off-switch",
        name: "PIR WALL-MOUNTED HIDDEN INFRARED MOTION SENSOR ON/OFF SWITCH",
        image: [
            "/assets/images/products/14.png",
            "/assets/images/products/15.png",
            "/assets/images/products/16.png",
            "/assets/images/products/17.png",
            "/assets/images/products/18.png",
            "/assets/images/products/19.png",
        ],
        model: "GP-03",
        description: "Geld Tech Presents our Energy saving Smart Sensor. A solution to the age-old problem of trying to find the light switches in the dark, Geld Tech’s Smart Sensor use Infrared Sensors built into it to perceive human presence. When it detects someone in its sensory area it automatically turns On, providing swift and bright illumination. And when no one is around, it turns the lights Off, saving hundreds of rupees in electricity bills as well as the environment. It can be widely used in hotels, corridors, staircases, warehouses, and other industrial areas. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Smart Switch Sensor - Energy saving Sensor, Energy Saving Smart Switch For Corridor Supplier, Energy Saving Smart Sensor For Corridor Supplier In Delhi, India",
        keyword: "Smart Switch Sensor, Energy saving Sensor, Energy Saving Smart Switch For Corridor Supplier, Energy Saving Smart Sensor For Corridor Supplier, Best Enery Saving Sensor for Rest Room Supplier",
        specification: [
            "Detection Range: Max. 160°",
            "Power Source: 220- 240V/AC",
            "Power Frequency: 50Hz",
            "Detection Distance: Max.9m(< 24 °c )",
            "Time Delay: 10sec± 3sec to 7min±2min(adjustable)",
            "Ambient Light: < 3- 2000LUX(adjustable)",
            "Installation Height: 1-1.8m}",
            "Protection Class: IP 20",
        ],
        application: [
            "Rest Room",
            "Office Cabins",
            "Lockers",
            "Stairways",
            "Lifts",
            "Toilets",
            "Main Entrance & Many More",
        ],
        category:"pir",
        hsn: 90329000,
    },
    {
        handle: "wall-mounte-pir-motion-sensor",
        name: "WALL MOUNT PIR MOTION SENSOR FOR LIGHT CONTROL",
        image: [
            "/assets/images/products/21.png",
            "/assets/images/products/22.png",
            "/assets/images/products/23.png",
            "/assets/images/products/24.png",
            "/assets/images/products/25.png",
            "/assets/images/products/26.png",
        ],
        model: "GP-04",
        description: "Geld Tech Presents our Wall Mount Motion Sensor. A wall-mounted Motion sensor, this device is working on a very simple principles via the tried and tested infrared wave detection method. This particular sensor possesses a much longer detection range and its sensor works over a wider angle, making it ideal for security purposes. This sensor is available in very attractive looks. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Wall Mount Sensor, Best Motion Sensor, Wall Mount Motion Sensor For Light Control, Wall Mount Motion Sensor For Main Gate Supplier In Delhi, India",
        keyword: "Wall Mount Sensor, Best Motion Sensor Supplier, Wall Mount Motion Sensor For Light Control Supplier, Wall Mount Motion Sensor For Main Gate Supplier",
        specification: [
            "Power Source: 220V - 240V AC",
            "Power Frequency: 50/60 Hz",
            "Ambient Light: < 3-2000 LUX(adjustable)",
            "Ambient Light: < 3-2000 LUX(adjustable)",
            "Time-Delay : Min. 10 sec ± 3 sec Max 7 min ± 2 min",
            "Rated Load: Max 1200W Min 300W",
            "Detection Range: 180°",
            "Detection Distance: Max. 12 meters ( < 24°C )",
            "Power Consumption: approx 0.5W",
            "Installation Height: 1.8 - 2.5 m",
            "Detection Moving Speed: 0.6 - 1.5 m,",
        ],
        application: [
            "Main Gate ",
            "Corridor",
            "Stairways ",
            "Basement",
            "Toilet ",
            "ATM",
            "Mall",
            "Office",
            "Parking Area",
            "Basement ",
            "Office",
            "Garage ",
            "Lift",
            "Balcony & Many More.",
        ],
        category:"pir",
        hsn: 90329000,
    },
    {
        handle: "pir-motion-sensor-indoor",
        name: "PIR MOTION SENSOR (INDOOR)",
        image: [
            "/assets/images/products/29.png",
            "/assets/images/products/30.png",
            "/assets/images/products/31.png",
            "/assets/images/products/32.png",
        ],
        model: "GP-05",
        description: "Geld Tech Presents our Indoor Wall Mount Motion Sensor. This hybrid-type sensor and light switch controller are intricate machines that combine their best qualities while covering any imperfections. Our sensor is capable of controlling up to 1200W of energy running through it. During the day, the Light-sensitive sensor switches off lights automatically and switches them on at night when the Infrared sensor detects someone in the surrounding. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Best Indoor Wall Mount Motion Sensor, Wall Mount Motion for Covered Parking, Indoor PIR Motion Sensor Supplier In Delhi, India",
        keyword: "Indoor Wall Mount Motion Sensor Supplier, Wall Mount Motion for Covered Parking Supplier, Indoor PIR Motion Sensor Supplier",
        specification: [
            "Detection Range: 180°",
            "Power Source: 220V- 240V/AC",
            "Power Frequency: 50/60Hz",
            "Rated Load: Max. 1200W (220-240V/AC)",
            "Detection Distance: 5- 12m ( < 24°c ) adjustable",
            "Time Delay: 10sec±3sec to 7min±2min(adjustable)",
            "Ambient Light: < 3- 2000LUX(adjustable)",
            "Installation Height: 1.8-2.5m",
            "Protection Class: IP44",
        ],
        application: [
            "Covered Parking",
            "Stairways ",
            "Garage",
            "Entrance ",
            "Corridors",
            "Office ",
            "Toilets",
            "ATM ",
            "Lockers ",
            "Pantry",
            "Basements ",
            "Store",
            "Lifts & Many More.",
        ],
        category:"pir",
        hsn: 90329000,
    },
    {
        handle: "pir-motion-sensor-waterproof",
        name: "PIR MOTION SENSOR (WATERPROOF)",
        image: [
            "/assets/images/products/43.png",
            "/assets/images/products/40.png",
            "/assets/images/products/41.png",
            "/assets/images/products/42.png",
        ],
        model: "GP-06",
        description: "Geld Tech Presents our Outdoor PIR Motion Sensor, specially designed for outdoor use, this impressive motion sensor pairs excellent motion detection arrays with a robust, all-weather, and waterproof exterior to make a comprehensive product. Good sensitivity and automated mechanism make this Sensor one of the best choices for outdoor venues and security systems. This sensor has waterproof protection class IP65 ratings. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Outdoor Motion Sensor, Outdoor PIR Motion Sensor, Best outdoor PIR Motion Sensor, Waterproof PIR Motion, PIR Motion for Garden Area Sensor Supplier In Delhi, India",
        keyword: "Outdoor Motion Sensor, outdoor PIR Motion Sensor Supplier, Waterproof PIR Motion Supplier, PIR Motion for Garden Area Sensor",
        specification: [
            "Detection Range: 180°",
            "Power Source: 220- 240VAC",
            "Power Frequency: 50Hz",
            "Rated Load Max.: 1200W (220-240V/AC)",
            "Detection Distance: Max.12m (< 24 °C )",
            "Time Delay: 10sec±3sec to 7min±2min(adjustable)",
            "Ambient Light: < 3- 2000LUX(adjustable)",
            "Detection Motion Speed: 0.6-1.5m/s",
            "Installation Height: 1.8-2.5m",
            "Protection Class: IP65",
        ],
        application: [
            "Parking area ",
            "Terrace",
            "Backyard ",
            "Garden Area",
            "Near Gate ",
            "Main Entrance",
            "Malls ",
            "ATM ",
            "Lifts",
            "Basement ",
            "Stairways",
            "Balcony ",
            "Toilets",
            "Many More.",
        ],
        category:"pir",
        hsn: 90329000,
    },
    {
        handle: "ceiling-mount-pir-sensor-with-light",
        name: "CEILING MOUNT PIR SENSOR WITH LIGHT",
        image: [
            "/assets/images/products/45.png",
            "/assets/images/products/46.png",
            "/assets/images/products/47.png",
            "/assets/images/products/48.png",
        ],
        model: "GP-07",
        description: "Geld Tech Presents our Ceiling Mount Sensor with light for stairs. We all know the pain and annoyance, while navigating in the dark, it might not hurt the body much but it defiantly dispels any pleasant thoughts. This device incorporates a PIR sensor with a ceiling light source that is switched ON as soon as it detects human presence. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Best Ceiling Mount Sensor With Light, PIR Motion Sensor with Light Supplier In Delhi, India",
        keyword: "Ceiling Mount, Sensor with Light, PIR Motion Sensor, Indoor PIR Motion Sensor Supplier",
        specification: [
            "Rated Voltage: 230V AC 50Hz",
            "Load Wattage: Max. 2x25W incandescent bulb or Max. 2x9W fluorescent lamps",
            "Detection Area: Max. 4 meters in radius",
            "Detection Angle: Max. 360o around",
            "Time-delay: From 10±5 seconds to 4±1 minutes adjustable",
            "LUX Control Level: From daytime to darkness adjustable",
            "Protection Class: IP20",
        ],
        application: [
            "Living Room",
            "Rest Room",
            "Corridor",
            "Verandan",
            "Lifts ",
            "Lift Lobby",
            "ATM’S ",
            "Mall",
            "Hospitals ",
            "Nursing Homes",
        ],
        category:"pir",
        hsn: 90329000,
    },
    {
        handle: "360degree-microwave-ceiling-mount-sensor",
        name: "MICROWAVE CEILING MOUNT SENSOR ",
        image: [
            "/assets/images/products/50.png",
            "/assets/images/products/51.png",
            "/assets/images/products/52.png",
        ],
        model: "GM-11",
        description: "Geld Tech Presents our Microwave Ceiling Mount Sensor. Unlike PIR sensors that function by detecting the very small movements generated by people in their sensory area, these Sensors function by using the electromagnetic properties of Microwaves. These ceiling-mounted microwave sensors are very efficient and sensitive to their surrounding and automatically start any electrical appliances (like led light, bulb, tube light, panel light, or fan) as soon as they detect someone. Highly Sensitive Range Sensor It can detect through doors, glass panels, and thin walls.",
        metaDescription:"False Ceiling Microwave Sensor - Microwave Sensor - Best Microwave Based Motion Sensor, Highly Sensitive Sensor Supplier, Microwave Based Motion Sensor Supplier In Delhi, India",
        keyword: "False Ceiling Microwave Sensor, Microwave Sensor, False Ceiling Sensor Supplier, PIR Based Motion Sensor Supplier, False Ceiling Sensor for offices Supplier",
        specification: [
            "Power Source: 220-240V AC",
            "Power Frequency: 50/60Hz",
            "Ambient Light: < 3-2000LUX(adjustable)",
            "Time Delay: 10sec±3sec to Max. 12min±1min",
            "Rated Load: 800W to 300W",
            "Detection Range: 360°",
            "Detection Distance: 1-8m (radius)adjustable",
            "Transmission Power: < 0.2mW",
            "Installation Height: 2 - 6 m",
        ],
        application: [
            "Office Cabin",
            "Meeting Hall",
            "Conference  Room",
            "Corridor ",
            "Basement",
            "ATM ",
            "Toilet",
            "Hospital, Restaurant",
            "Covered Parking Area & many more",
        ],
        category:"microwave",
        hsn: 90329000,
    },
    {
        handle: "360degree-microwave-motion-sensor",
        name: "360° MICROWAVE MOTION SENSOR ",
        image: [
            "/assets/images/products/54.png",
            "/assets/images/products/55.png",
            "/assets/images/products/56.png",
            "/assets/images/products/57.png",
            "/assets/images/products/58.png",
            "/assets/images/products/59.png",
        ],
        model: "GM-12",
        description: "Geld Tech Presents our Microwave Motion Sensor Wall/Ceiling. Using the microwave portion of the electromagnetic spectrum, we have designed this sophisticated sensor that can detect people in a comprehensive 360-degree range. It automatically distinguishes between night and day and can also start the load, in this case, the lights, when it detects motion in its range of observation. This sensor is very small just like a matchbox, which can be hidden in a false ceiling/display panel. Motion detection is possible through doors, glass, and thin walls.",
        metaDescription:"Best Microwave Motion Sensor, Time and Sensitivity Adjustable Sensor Supplier In Delhi, India",
        keyword: "Microwave Motion Sensor Supplier, Time and Sensitivity Adjustable Sensor Supplier",
        specification: [
            "Power Source: 220- 240VAC",
            "Power Frequency: 50/60Hz HF",
            "Ambient Light: < 3-2000 LUX(adjustable)",
            "Time Delay: 10 sec±3 sec to 12min ±1min",
            "Rated Load: 1200W to 300W",
            "Detection Range: 360° / 180°",
            "Detection Distance: Wall: 5-15m (adjustable) Ceiling: 1-8m (radius), adjustable",
            "Transmission Power:< 0.2mW",
            "Installation Height:Wall: 1.5 - 3.5m Ceiling: 2 - 8m",
        ],
        application: [
            "Reception",
            "Office Cabin",
            "Meeting Room",
            "Corridor ",
            "ATM",
            "Mall ",
            "Store ",
            "Garage",
            "Basement Store Lift & Many More",
        ],
        category:"microwave",
        hsn: 90329000,
    },
    {
        handle: "360degree-false-ceiling-microwave-sensor",
        name: "MICROWAVE MOTION SENSOR(FALSE CEILING)",
        image: [
            "/assets/images/products/61.png",
            "/assets/images/products/62.png",
            "/assets/images/products/63.png",
            "/assets/images/products/64.png",
            "/assets/images/products/65.png",
        ],
        model: "GM-13",
        description: "Geld Tech Presents our Microwave Motion Sensor (False Ceiling). Our microwave sensors work by detecting human motion. These sensors send out a pulse of microwaves which when reflected to the sensor are used to calculate whether there is motion (even very small motions like hand waves) in the field of observation of the sensor. If any motion is detected the sensors switch on the lights. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement. Attractive look.",
        metaDescription:"False Ceiling Microwave Sensor - Microwave Sensor - Best Microwave Based Motion Sensor, Highly Sensitive Sensor Supplier, Microwave Based Motion Sensor Supplier In Delhi, India",
        keyword: "False Ceiling Microwave Sensor, Microwave Sensor, False Ceiling Sensor Supplier, PIR Based Motion Sensor Supplier, False Ceiling Sensor for offices Supplier",
        specification: [
            "Power Sourcing: 220- 240V/AC",
            "Power Frequency: 50Hz",
            "Ambient Light: < 3- 2000LUX(adjustable)",
            "Time Delay: Min. 10sec ± 3sec Max 12min ± 1min",
            "Rated Load: Max.1200W - 300W",
            "Detection Range: 360°",
            "Detection Distance: 1-8m(radius), adjustable",
            "HF System: 5.8GHz CW radar, ISM band",
            "Transmission Power: < 0.2mW",
            "Installation Height: 1.5-3.5m",
        ],
        application: [
            "Office ",
            "Cabin",
            "Meeting Room",
            "Corridor ",
            "Basement",
            "ATM ",
            "Mall ",
            "Store",
            "Conference Hall & Many More",
        ],
        category:"microwave",
        hsn: 90329000,
    },
    {
        handle: "360degree-ceiling-occupancy-sensor",
        name: "360° CEILING MOUNT OCCUPANCY SENSOR",
        image: [
            "/assets/images/products/67.png",
            "/assets/images/products/68.png",
            "/assets/images/products/69.png",
            "/assets/images/products/70.png",
        ],
        model: "G0-16",
        description: "Geld Tech Presents our Ceiling Mount Occupancy Sensor. As the name suggests, this Ceiling mount occupancy sensor is widely used as a sensor light and room occupancy detector. In homes with elderly individuals or children, occupancy sensor lights are a very efficient and hassle-free way of providing illumination without inviting any mishaps. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Sensor room occupancy detection, Ceiling Mount Occupancy Sensor, Occupancy Sensor Supplier In Delhi, India",
        keyword: "Ceiling Mount Occupancy Sensor Supplier, Sensor room occupancy detection, Occupancy Sensor Supplier",
        specification: [
            "Power requirement: 220 ~ 240VAC / 50Hz",
            "Rated Load: 2,000W",
            "Detection Angle: 360°",
            "Detection Range: 20 Meters",
            "Run-on time setting: 10 sec. ~ 30 min,",
            "Ambient light setting: 3 ~ 2000 Lux. (adjustable)",
            "Warm-up time: Approximately 1 minute",
            "Working temp.: -20°C~ 40°C",
            "Dimension (W*L*D):105mmX105 mmX60mm",
        ],
        application: [
            "Meeting Halls ",
            "Conference Rooms",
            "Office Cabins",
            "Corridor",
            "ATM ",
            "Malls",
            "Store Rooms & Many More !!!",
        ],
        category:"occupancy",
        hsn: 90329000,
    },
    {
        handle: "360degree-false-ceiling-occupancy-sensor",
        name: "360° FALSE MOUNT OCCUPANCY SENSOR ",
        image: [
            "/assets/images/products/72.png",
            "/assets/images/products/73.png",
            "/assets/images/products/74.png",
            "/assets/images/products/75.png",
        ],
        model: "G0-17",
        description: "Geld Tech Presents our Occupancy Sensor with False Mount. One of the most efficient lighting solutions available for a personal residence, Occupancy sensor lights are wonderful devices that switch on the Lights only when there is an occupant in the room and switches the light Off when there isn’t. This Occupancy Sensor can distinguish between day and night and uses infrared for its detection purposes. But we can opt only for night mode as per requirement.",
        metaDescription:"Occupancy Sensor - Occupancy Sensor False Mount, infrared Based Sensor, Occupancy Based Sensor Supplier In Delhi, India",
        keyword: "Occupancy Sensor, Occupancy Sensor False Mount Supplier, infrared Based Sensor Supplier, Occupancy Based Sensor Supplier",
        specification: [
            "Power requirement: 220 ~ 240VAC / 50Hz",
            "Rated Load: 2,000W",
            "Detection Angle: 360°",
            "Detection Range: 3-20 Meters(adjustable)",
            "Run-on time setting: 10 sec. ~ 30 min,",
            "Ambient light setting: 3 ~ 2000 Lux. (adjustable)",
            "Warm-up time: Approximately 1 minute",
            "Working temp.: -20°C~ 40°C",
            "Power consumption: approx 0.5W",
            "Installation Height: 2.2-6m",
        ],
        application: [
            "Office Cabin",
            "Meeting Room",
            "Corridor ",
            "ATM ",
            "Mall",
            "Store ",
            "Conference Hall",
            "Basement",
            "Warehouses & Many More",
        ],
        category:"occupancy",
        hsn: 90329000,
    },
    {
        handle: "security-sensor-with-led-flood-light",
        name: "SECURITY SENSOR WITH LED FLOOD LIGHT ",
        image: [
            "/assets/images/products/77.png",
            "/assets/images/products/78.png",
            "/assets/images/products/79.png",
            "/assets/images/products/80.png",
        ],
        model: "GS-21",
        description: "Geld Tech presents our security sensor with Led Flood Light. Everybody wants to sleep in peace, but our motion sensor can help you to keep out of it. Motion sensors silently off as someone (human) come in its sensory range on the flood light (with optional hooter) to alert the security personnel about the unwanted guest. This system is designed to switch itself off later after a set time has elapsed. It automatically distinguishes between day and night, but we can opt only for night mode as per requirement.",
        metaDescription:"Security Sensor with LED Flood light - Security Sensor With LED Flood Light, LED Flood Light Sensor Supplier In Delhi, India",
        keyword: "Security Sensor with LED Flood light, Security Sensor With LED Flood Light supplier, Security Sensor supplier, LED Flood Light Sensor supplier",
        specification: [
            "Detection Range: 180°",
            "Power Source: 110- 130V/AC 220-240V/AC",
            "Power Frequency: 50/60Hz",
            "Rated Load: Max. 1200W (220-240V/AC)",
            "Detection Distance: 5- 12m (< 24 °C ) adjustable",
            "Time Delay: 10sec ±3sec to 7min ±2min(adjustable)",
            "Ambient Light: < 3- 2000LUX(adjustable)",
            "Detection Motion Speed: 0.6-1.5m/s",
        ],
        application: [
            "Covered Parking Area",
            "Backyard Area",
            "Garden Area",
            "Balcony ",
            "Stairways",
            "Terrace ",
            "Basements",
            "Shop ",
            "Restricted Areas",
            "Factory",
            "Construction Site",
        ],
        category:"light",
        hsn: 90329000,
    },
    {
        handle: "photocell-day-night-sensor",
        name: "PHOTOCELL DAY NIGHT SENSOR",
        image: [
            "/assets/images/products/82.png",
            "/assets/images/products/83.png",
            "/assets/images/products/84.png",
            "/assets/images/products/85.png",
            "/assets/images/products/86.png",
        ],
        model: "GD-26",
        description: "Geld Tech Presents our Photocell Day Night Sensor. Photocell sensors are electrical devices that detect the amount of ambient light present in the environment by dynamically changing the switch state. This detector allows us to create smart devices that automatically switch On at night and Switches Off in the morning. It can work with only one series of lights (12 to 15 led lights)",
        metaDescription:"Photocell Day Night Sensor, High Quality Photocell Day Night Sensor (Series) Supplier, Day Night Sensor Supplier In Delhi, India",
        keyword: "Photocell Day Night Sensor, Photocell Day Night Sensor (Series), Photocell Sensor Series, Day Night Sensor",
        specification: [
            "Material: PC (UV Proof)",
            "Power Source: 220- 240VAC",
            "Power Frequency: 50Hz Rated Current: 20A",
            "Ambient Light: < 2- 100LUX (adjustable)",
            "IP65",
            "Max Size: 95xx65x35mm",
        ],
        application: [
            "Automatic ON/OFF of street lights as per DAY-NIGHT mode.",
            "Install any indoor and outdoor lights & Many more",
        ],
        category:"photocell",
        hsn: 90329000,
    },
    {
        handle: "photocell-day-night-sensor-10a",
        name: "PHOTOCELL SENSOR(DAY NIGHT) 10A",
        image: [
            "/assets/images/products/88.png",
            "/assets/images/products/89.png",
            "/assets/images/products/90.png",
            "/assets/images/products/91.png",
            "/assets/images/products/92.png",
            "/assets/images/products/93.png",
        ],
        model: "GD-27",
        description: "Geld Tech Presents our Photocell Sensor. With this particular sensor, we can distinguish between Day and Night allowing us to create energy-saving, efficient, adjustable, and automatic lights that Switch themselves On and Off as per the ambient light in the environment around it. This sensor requires a power source of around 220-240 AC at 50/60 Hz.",
        metaDescription:"High Quality 10 A Photocell Day Night Sensor Supplier, Day Night Sensor Supplier In Delhi, India",
        keyword: "Photocell Sensor (Day-Night) 10A Supplier, Photocell Sensor 10A, Day Night Sensor",
        specification: [
            "Power Source: 220-240V/AC",
            "Rated Current: 10A",
            "Working Temperature: -20+40°C",
            "Power Frequency: 50/60Hz",
            "Ambient light: < 5-50 Lux",
            "Working Humidity: < 93% RH",
        ],
        application: [
            "Street Light",
            "Garden Lights",
            "Incandescent lamp",
            "LED lights",
            "Balcony Lights",
            "Outdoor Lights",
        ],
        category:"photocell",
        hsn: 90329000,
    },
    {
        handle: "photocell-day-night-sensor-6a",
        name: "PHOTOCELL SENSOR(DAY NIGHT) 6A",
        image: [
            "/assets/images/products/95.png",
            "/assets/images/products/96.png",
            "/assets/images/products/97.png",
            "/assets/images/products/98.png",
        ],
        model: "GD-28",
        description: "Geld Tech Presents our Photocell Sensor. This particular sensor is rated for a 6-ampere current and requires a power source of around 220-240 AC at 50/60 Hz. This product can act as a switch in integration with other devices as this sensor enables them to start and stop as per the ambient light around the device. It works with only one single light.",
        metaDescription:"High Quality Photocell Sensor (Day-Night) 6A Supplier, Day Night Sensor Supplier In Delhi, India",
        keyword: "Photocell Sensor (Day-Night) 6A Supplier, Photocell Sensor 6A, Day Night Sensor",
        specification: [
            "Power Source: 220-240V/AC",
            "Rated Current: 6A",
            "Working Temperature: -20+40°C",
            "Power Frequency: 50/60Hz",
            "Ambient light: < 5-15 Lux",
            "Working Humidity: < 93% RH",
        ],
        application: [
            "Street Light",
            "Garden Lights",
            "Incandescent lamp",
            "LED lights",
            "Balcony Lights",
            "Outdoor Lights",
        ],
        category:"photocell",
        hsn: 90329000,
    },
    {
        handle: "photocell-day-night-sensor-ldr",
        name: "Photocell Day Night Sensor (LDR)",
        image: [
            "/assets/images/products/158.png",
            "/assets/images/products/159.png",
            "/assets/images/products/160.png",
            "/assets/images/products/161.png",
        ],
        model: "GD-30",
        description: "Photocell day night sensor (Dusk To Dawn) LDR. Can turn ON or OFF light by automatically according to ambient light. It can control the load working only at night. Smart device that automatically switches on at night and switches OFF in the morning.",
        specification: [
            "Power Source: 220-240V/AC",
            "Rated Current: 6A/10Amp",
            "Working Temperature: -20+40°C",
            "Power Frequency: 50/60Hz",
            "Working Humidity: < 93% RH",
            "Material: Nylon/ Plastic",
            "Color: White",
        ],
        application: [
            "Street Light",
            "Garden Lights",
            "Balcony Lights",
            "Outdoor Lights",
        ],
        category:"photocell",
        hsn: 90329000,
    },
    {
        handle: "smoke-alarm-sensor",
        name: "SMOKE ALARM SENSOR",
        image: [
            "/assets/images/products/100.png",
            "/assets/images/products/101.png",
            "/assets/images/products/102.png",
            "/assets/images/products/103.png",
            "/assets/images/products/104.png",
        ],
        model: "GD-29",
        description: "Geld Tech Presents our Smoke Alarm Sensor. Smoke Alarms or fire alarms, as they are known more commonly, are fire-detecting sensors that use the smoke created in a fire to raise an alarm as well as start the fire suppression system in the building. Smoke alarms can offer a few crucial seconds of warning in case of any impending disaster.",
        metaDescription:"High Quality Smoke Alarm Sensor Supplier, Smoke Alarm Supplier In Delhi, India",
        keyword: "Smoke Alarm Sensor, Smoke Alarm",
        specification: [
            "Power: 9V/DC battery operated",
            "Working Consumption: < 10mA",
            "Working Temperature: -20°C ~ 40°C",
            "Static Consumption: < 8uA",
            "Alarm Sound: ≥ 85db (3m)",
        ],
        application: [
            "Stairways / Lobby",
            "Covered Parking Area",
            "Lockers Room",
            "Office / Malls",
            "And Many More!!!",
        ],
        category:"security",
        hsn: 90329000,
    },
    {
        handle: "wardrobe-cabinet-ir-sensor",
        name: "WARDROBE/CABINET SMART IR SENSOR FOR LIGHT ON/OFF",
        image: [
            "/assets/images/products/106.png",
            "/assets/images/products/107.png",
            "/assets/images/products/108.png",
            "/assets/images/products/109.png",
        ],
        model: "GIR-31",
        description: "Geld Tech Presents our Infrared Sensor. This is a short distance Infrared Sensor that is widely used to switch the Lights On as the door opens. The sensor then turns the lights Off when the door closes.",
        metaDescription:"High Quality IR Sensor Supplier, IR Sensor Supplier In Delhi, India",
        keyword: "IR Sensor, Infrared Sensor",
        specification: [
            "Power Source: 220-240V/AC",
            "Power Frequency: 50/60Hz",
            "Rated Current: 5A",
            "Detection Distance: 5-6 cm",
            "Rated Load: 500W - 200W",
            "Working Temperature: -20+50°C",
        ],
        application: [
            "Wardrobe",
        ],
        category:"IR",
        hsn: 90329000,
    },
    {
        handle: "motion-sensor-led-bulb",
        name: "MOTION SENSOR LED BULB",
        image: [
            "/assets/images/products/111.png",
            "/assets/images/products/112.png",
            "/assets/images/products/113.png",
        ],
        model: "GT-SB01",
        description: "Geld tech motion sensor LED Bulb switches ON by sensing any movement within a range of 15 ft. and switches OFF automatically when no motion is detected for 2 min. This bulb will automatically turn ON as it detects motion and LUX more than 50%. The bulb will not glow during day time. This will not work in presence of there light in the room.",
        metaDescription:"LED Motion Sensor Bulb with automatic ON/OFF - Geld Tech Sensor",
        keyword: "LED, Motion sensor, LED Motion Sensor Bulb",
        specification: [
            "Wattage: 9-watt &10 wattage",
            "Base: B-22",
            "Voltage: 220v-230v,50HZ",
            "Lumen: 900lm(c.w)",
            "Sensor Type: Auto ON/OFF(HF)",
            "CRI: >80",
        ],
        application: [
            "Warehouse",
            "Parking",
            "washroom",
            "Basement",
            "Stairways",
            "Trial Room",
            "Lift",
            "Shop",
            "Office/Mall",
            "Corridor & Many More",
        ],
        category:"light",
        hsn: 90329000,
    },
    {
        handle: "motion-sensor-dimmable-led-tube-light",
        name: "MOTION SENSOR DIMMABLE LED TUBE LIGHT",
        image: [
            "/assets/images/products/115.png",
            "/assets/images/products/116.png",
            "/assets/images/products/117.png",
            "/assets/images/products/118.png",
            "/assets/images/products/119.png",
        ],
        model: "GT-ST02",
        description: "Geld sensor based dimmable T-5 LED tube light 20w HF motion sensor auto dimmable cool white, and when dimmable takes 6W. This is an ultimate replacement of fluorescent Tubes. It has an in-built smart sensing device to turn FULL BRIGHT 20w When people are in range and auto DIM only when they leave. Its unique design creates a perfectly uniform visual appearance. An effective solution to reduce. Electricity charges and full energy-saving products.",
        metaDescription:"LED Motion Sensor Tubelight with automatic dimming - Geld Tech Sensor",
        keyword: "LED, Motion sensor, LED Motion Sensor Tubelight",
        specification: [
            "Wattage: 20w",
            "Voltage: 220v-240v",
            "Power Consumption: 20watts(Full Bright)& 6watts(Dimmable)",
            "IP Rating: IP 20",
            "Detection Distance: 6 Meters",
            "Beam Angle: 120c",
            "CRI: 80",
            "Sensor Type: Microwave auto ON/OFF",
        ],
        application: [
            "House",
            "Washroom",
            "Factory",
            "Office",
            "Packing Area",
            "Starways",
            "Basement",
            "Mall",
            "Lobby",
            "Warehouse",
        ],
        category:"light",
        hsn: 90329000,
    },
    {
        handle: "motion-sensor-led-tube-light",
        name: "MOTION SENSOR LED TUBE LIGHT",
        image: [
            "/assets/images/products/118.png",
            "/assets/images/products/115.png",
            "/assets/images/products/116.png",
            "/assets/images/products/117.png",
            "/assets/images/products/119.png",
        ],
        model: "GT-ST03",
        description: "Geld tech sensor-based LED TUBELIGHT T-5, 20-watt present fully automatically turns on/off cool white energy saving. It has an inbuilt smart sensor device to turn automatically ON as it detects motion and stays put as long as locomotion is present. When there is no active human presence the tube light will be deactivated automatically. It is easy to install and is used widely.",
        metaDescription:"LED Motion Sensor Tubelight with automatic ON/OFF - Geld Tech Sensor",
        keyword: "LED, Motion sensor, LED Motion Sensor Tubelight",
        specification: [
            "Input Voltage: 220V- 240V",
            "Input Supply Frequency: 50-60 HZ",
            "Sensor Range: Upto 6M",
            "Shape: Square",
            "Watts: 18W,22W,36W",
            "Lifetime: 50,000+ Hours",
            "ON/OFF: Automatically",
            "Material: Plastic",
            "Length: 4 Feet",
            "Radius: 22 MM",
            "Diameter: 1190 MM",
        ],
        application: [
            "House",
            "Washroom",
            "Factory",
            "Office",
            "Packing Area",
            "Starways",
            "Basement",
            "Mall",
            "Lobby",
            "Warehouse",
        ],
        category:"light",
        hsn: 90329000,
    },
    {
        handle: "square-led-dimmable-panel-light",
        name: "SQUARE LED AUTO DIMMABLE PANEL LIGHT",
        image: [
            "/assets/images/products/121.png",
            "/assets/images/products/122.png",
            "/assets/images/products/123.png",
            "/assets/images/products/124.png",
            "/assets/images/products/125.png",
            "/assets/images/products/126.png",
            "/assets/images/products/127.png",
        ],
        model: "GT-SP04",
        description: "Geld Tech sensor-based square panel LED light. It has an inbuilt smart sensor device to turn automatically ON as it detects motion and stays put as long as locomotion is present. When there is no active human presence the panel light will be deactivated automatically. It is easy to install and is used widely.",
        metaDescription:"LED Motion Sensor Square panel light with automatic dimming - Geld Tech Sensor",
        keyword: "LED, Motion sensor, LED Motion Sensor Square panel light",
        specification: [
            "Input Voltage: 220V- 240V",
            "Input Supply Frequency: 50 HZ",
            "ON/OFF: Automatically",
            "Lifetime: 50,000+ Hours",
            "Shape: Square",
            "Waltage: 12W,15W,18W,20W",
        ],
        application: [
            "House",
            "Washroom",
            "Factory",
            "Office",
            "Packing Area",
            "Basement",
            "Mall",
            "Lobby",
            "Warehouse",
        ],
        category:"light",
        hsn: 90329000,
    },
    {
        handle: "circle-led-dimmable-panel-light",
        name: "CIRCLE-LED AUTO DIMMABLE PANEL LIGHT",
        image: [
            "/assets/images/products/129.png",
            "/assets/images/products/130.png",
            "/assets/images/products/131.png",
            "/assets/images/products/132.png",
        ],
        model: "GT-SP05",
        description: "Geld Tech sensor-based round panel LED light. It has an inbuilt smart sensor device to turn automatically ON as it detects motion and stays put as long as locomotion is present. When there is no active human presence the panel light will be deactivated automatically. It is easy to install and is used widely.",
        metaDescription:"LED Motion Sensor Circle panel Light with automatic ON/OFF - Geld Tech Sensor",
        keyword: "LED, Motion sensor, LED Motion Sensor Circle panel Light",
        specification: [
            "Input Voltage: 220V- 240V",
            "Input Supply Frequency: 50 HZ",
            "ON/OFF: Automatically",
            "Lifetime: 50,000+ Hours",
            "Shape: Round",
            "Waltage: 12W,15W,18W,20W",
        ],
        application: [
            "House",
            "Washroom",
            "Factory",
            "Office",
            "Packing Area",
            "Basement",
            "Mall",
            "Lobby",
            "Warehouse",
        ],
        category:"light",
        hsn: 90329000,
    },
    {
        handle: "ir-double-door-sensor",
        name: "IR Double Door Sensor",
        image: [
            "/assets/images/products/146.png",
            "/assets/images/products/147.png",
        ],
        model: "GIR-32",
        description: "Geld Tech IR double Door Wardrobe Sensor, This Product has two sensors connecte together for a wardrobe that has two doors. Thus if either door opens the light will get switched on. Energy saving switch for Led soft light strip wardrobe cabinet light. Save on electricity bill. Automatically ON/OFF when opening/closing the cabinet door, effective energy saving products.",
        metaDescription:"",
        keyword: "",
        specification: [
            "Type: IR Double Door Sensor",
            "Power Source: DC 12-24V",
            "Max Load: 12V 60w/24V 100w",
            "Body Color: Black",
        ],
        application: [
            "Wardrobe",
            "Kitchen",
            "Cupboard",
        ],
        category:"IR",
        hsn: 90329000,
    },
    {
        handle: "wardrobe-door-switch-auto-push",
        name: "Wardrobe Door Switch (Auto Push)",
        image: [
            "/assets/images/products/148.png",
            "/assets/images/products/149.png",
            "/assets/images/products/150.png",
            "/assets/images/products/151.png",
        ],
        model: "GW-01",
        description: "Geld Tech Sensor Wordrobe door auto push switch. This switch is great for using on cupboard/ wardrobe door light, turns off when door is closed & switch is pushed inwards. Switch ON/OFF light inside a wardrobe or any frequently opened furniture where the light goes OFF as soon as you close. Give your furniture a sophesticated look with this ON/OFF Switch",
        metaDescription:"",
        keyword: "",
        specification: [
            "Brand: Geld Tech",
            "Switch Type: Push Button Switch",
            "Body Material: Plastic",
            "Contact Type: Normally Open",
            "Operation Mode: ON/OFF",
            "Voltage Current Rating: 250V/2Amp",
        ],
        application: [
            "Wardrobe",
            "Cabinets",
            "Book Cases",
            "Wall Cabinets",
            "Sliding Door",
            "Cupboard",
        ],
        category:"push",
        hsn: 90329000,
    },
    {
        handle: "motion-sensor-foot-light",
        name: "Motion Sensor Foot Light",
        image: [
            "/assets/images/products/152.png",
            "/assets/images/products/153.png",
            "/assets/images/products/154.png",
        ],
        model: "GM-14",
        description: "Motion sensor based foot light made of Aluminum diecast Powder coated good heat dissipation, high temerature resistance, suitable for both indoor and outdoors. IP Protected. It only turns On in low light levels by detecting your movement. It uses microwave motion sensor technology and is built in. Can be installed at Driveways, pathways, stairs, side table, next to bed, etc. ",
        metaDescription:"",
        keyword: "",
        specification: [
            "Brand: Geld Tech",
            "Led Color: White",
            "Bulb Type: LED",
            "Wattage: 3 watt",
            "Power Source: AC 220V/240V",
            "Body Material: Aluminium",
            "Body Color: Grey/White/Black",
        ],
        application: [
            "Stair",
            "Garden Wall",
            "Pathways",
            "Drive Ways",
            "Hotels",
            "Residence areas",
            "Showrooms",
        ],
        category:"microwave",
        hsn: 90329000,
    },
    {
        handle: "wardrobe-cabinet-hinge-light",
        name: "Wardrobe Cabinet Hinge Light",
        image: [
            "/assets/images/products/148.png",
            "/assets/images/products/149.png",
            "/assets/images/products/150.png",
            "/assets/images/products/151.png",
        ],
        model: "GW-02",
        description: "The Led lamp will turn on automatically when opening the cabinet and the light turn OFF when closing the cabinet. You don't need to take a flashlight when finding something in dark.",
        metaDescription:"",
        keyword: "",
        specification: [
            "Type: Smart LED Light",
            "Power Source: Battery Operated (Included with the product)",
            "Wattage: 2w",
            "Body Color: Grey",
            "Material: ABS",
            "Power Requirement: 12V",
        ],
        application: [
            "Kitchen Drawer",
            "Wardrobe/ Cupboard",
            "Cabinet",
            "Almirah",
            "Pantries",
        ],
        category:"push",
        hsn: 90329000,
    },
    {
        handle: "infrared-chargable-night-lamp",
        name: "Infrared Chargable Night Lamp",
        image: [
            "/assets/images/products/155.png",
            "/assets/images/products/156.png",
            "/assets/images/products/157.png",
        ],
        model: "GIR-33",
        description: "Infrared chargable night lamp is energy saving product. USB Chargin _ Long life use its automatically ON/OFF by sensing motion. Manual ON/OFF option and torch light comes additional with it. Best option for indoor & outdoor purpose. Motion sensor light can only be turned ON in darkness envirenment. It won't turn of if enough light has been detected by the light sensor and turns off automatically after 20 seconds when no motion is detected. Lithium rechargable battery, 2 hours required for a full charge. Has charging indicatior for show the progress. Truns on green once it is fully charged. Once fully charged it works for more than 7 days in auto mode.",
        metaDescription:"",
        keyword: "",
        specification: [
            "Type: LED",
            "Detection Range: 140°",
            "Detection Distance: 6m max (<24°C)",
            "Ambient light: <10-20LUX",
            "Rated Load: Flashlight: 1W, Night Lamp: 0.8W",
            "Switch Function: Auto/ON/OFF",
            "Detection Moving Speed: 0.6 - 1.5m/s",
            "Body Color: White",
        ],
        application: [
            "Kitchen",
            "Washroom",
            "Bedroom",
            "Hallways",
            "Wardrobe",
            "Corridor",
            "Stair",
            "Cupboard",
        ],
        category:"IR",
        hsn: 90329000,
    },
    // {
    //     handle: "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj",
    //     name: "kkkkkkkkkkkkkkkk",
    //     image: [
    //         "/assets/images/products/1.png",
    //     ],
    //     model: "ddddddddddddddddddddddddddddddddd",
    //     description: "dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd",
    //     metaDescription:"",
    //     keyword: "",
    //     specification: [
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //         "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
    //     ],
    //     application: [
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //         "mmmmmmmmmmmmmmmmmmmm",
    //     ],
    //     category:"pir",
    //     hsn: 90329000,
    // },
]