import React, { useEffect, useState } from 'react'
import moment from 'moment'
import html2canvas from 'html2canvas'
import generatePDF, { Margin, Resolution } from 'react-to-pdf';

const PrintHandler = ({details, type}) => {

  const numWords = require('num-words')

  let printBill = (new URLSearchParams(window.location.search)).get("print")
  const [printCount, setPrintCount] = useState(0)

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    if (details && details.items && printCount<1 && printBill) {
        setTimeout(() => {
            setPrintCount(printCount + 1)
            handlePrint()
        }, 1000);
    }
  }, [details, printCount])

  useEffect(() => {
    document.title = `${type==="bill" ? "GTS" : "PI"}-${details.taxYear}-${type==="bill" ? details.taxNumber : details.invoiceNumber} | ${details.billTo.name}`; 
  }, [])
  
  const handleDownloadImage = async () => {
    console.log("Running Download Image")
    const element = document.querySelector('.bill-content'),
    canvas = await html2canvas(element),
    data = canvas.toDataURL('image/jpg'),
    link = document.createElement('a');

    link.href = data;
    link.download = `${type==="bill" ? "GTS" : "PI"}-${details.taxYear}-${type==="bill" ? details.taxNumber : details.invoiceNumber} | ${details.billTo.name}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadPDF = () => {
    const options = {
        page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.MEDIUM,
        },
    };
    const getTargetElement = () => document.querySelector('.bill-content');
    generatePDF(getTargetElement, {filename: `${type==="bill" ? "GTS" : "PI"}-${details.taxYear}-${type==="bill" ? details.taxNumber : details.invoiceNumber} | ${details.billTo.name}`}, options)
  }
    


  return (
    <>
        <section className='d-print-none'>
            <nav className="navbar" style={{minWidth:"210mm"}}>
                <div className="container">
                    <a className="navbar-brand" href="/"><img className='nav-logo'
                            src="/assets/images/logo/logo-navbar-tertiary.svg" alt={`Geld Tech Sensor`} /></a>
                    <div className='d-flex gap-2'>
                        {/* <button className='btn btn-tertiary' onClick={(handleDownloadPDF)}>Download PDF</button> */}
                        <button className='btn btn-tertiary' onClick={handleDownloadImage}>Download Image</button>
                        <button className='btn btn-tertiary' onClick={handlePrint}>Print /Download Invoice</button>
                    </div>
                </div>
            </nav>
        </section>
        <section className='print-section' data-bs-theme={type==="bill" ? "bill" : ""}>
            <div className='bill-content'>
                {/* Header */}
                <div className="invoice-header">
                    {/* LOGO */}
                    <div className='logo-div'>
                        <h1 className='fw-bold text-tertiary mb-0'>{type==="bill" ? "Tax" : "Performa"} Invoice</h1>
                        {type === "bill" ? <img src="/assets/images/logo/logo-bill.svg" height={100} alt="" /> : <img src="/assets/images/logo/logo-login.svg" height={100} alt="" />}
                    </div>
                    {/* Contact Details */}
                    <div className='contact-details'>
                        <h1 className='fw-bold text-tertiary mb-0'>Geld Tech Sensor</h1>
                        <div className='contact-details'>
                            <p><strong>GSTIN No.:</strong>07AMXPK0747R1Z2</p>
                            <div className='flex-between gap-2'>
                                <p>+91 9212316065 <img src="/assets/images/icons/pi/phone.svg" alt="" /></p>
                                <p>+91 9212316065 <img src="/assets/images/icons/pi/whatsapp.svg" alt="" /></p>
                            </div>
                            <p>geldtechsensor@gmail.com <img src="/assets/images/icons/pi/email.svg" alt="" /></p>
                            <p>https://www.geldtechsensor.com/ <img src="/assets/images/icons/pi/website.svg" alt="" /></p>
                            <p>Plot No. 138, Ganesh Nagar, Burari, Delhi - 110084 <img src="/assets/images/icons/pi/address.svg" alt="" /></p>
                        </div>
                    </div>
                </div>
                {/* Bill To - Place Of Supply - Invoice No. and Date */}
                <div className="bill-to">
                    <div>
                        <p className="fw-medium">Bill To</p>
                        <p>{details.billTo.name}</p>
                        <p>{details.billTo.address}</p>
                        <p>{details.billTo.gst}</p>
                    </div>
                    <div>
                        <p className="fw-medium">Place Of Supply</p>
                        <p>{details.placeOfSupply.name}</p>
                        <p>{details.placeOfSupply.address}</p>
                        <p>{details.placeOfSupply.gst}</p>
                    </div>
                    <div className="invoice-number-div">
                        <p className="fw-medium">{type==="bill" ? "Tax No." : "Invoice No."}</p>
                        <p>{`${type==="bill" ? "GTS" : "PI"}-${details.taxYear}-${type==="bill" ? details.taxNumber : details.invoiceNumber}`}</p>
                        <p>{moment(details.createdAt).format('D MMM YYYY')}</p>
                    </div>
                </div>
                {/* Items */}
                <div className="item-header">
                    <span className='item-header-s-no'>S.No</span>
                    <span className='item-header-image'>Image</span>
                    <span className='item-header-name'>Name</span>
                    <span className='item-header-hsn'>HSN</span>
                    <span className='item-header-qty'>Qty</span>
                    <span className='item-header-price'>Price</span>
                    <span className='item-header-amount'>Amount</span>
                </div>
                {details.items && details.items.map((item, index)=>(
                    <div key={index} className={`item-row ${index%2===0 ? 'bg-white' : 'bg-item-dark'}`}>
                        <span className='item-header-s-no'>{index+1}</span>
                        <span className='item-header-image'><img src={item.image} alt="" /></span>
                        <span className='item-header-name'>{item.name}</span>
                        <span className='item-header-hsn'>{item.hsn}</span>
                        <span className='item-header-qty'>{item.quantity}</span>
                        <span className='item-header-price'>{item.price}</span>
                        <span className='item-header-amount'>{item.quantity * item.price}</span>
                    </div>
                ))}
                {/* Total amount  and T&C*/}
                <div className="total-tnc">
                    {/* Words and T&C */}
                    <div className='words-tnc'>
                        <p className='num-to-words'>{numWords(Math.trunc(details.totalAmount))} Only</p>
                        <div className='pe-5'>
                            <h6>Terms & Conditions:</h6>
                            {/* <ol type='1'>
                                <li>Payment terms 100% Advance</li>
                                <li>GST 18% Extra</li>
                                <li>Motion Sensor 12 Months warranty against manufacturing defects only (Burn physical damage, burn due to coming in contact with water is not included in warranty)</li>
                                <li>Goods once sold will not be taken back</li>
                                <li>All disputes are subject to Delhi jurisdiction only</li>
                                <li>Shipping: dispatch by Road/Air & Freight amount will be extra</li>
                            </ol> */}
                            <span>1. Payment terms 100% Advance</span>
                            <br />
                            <span>2. GST 18% Extra</span>
                            <br />
                            <span>3. Motion Sensor 12 Months warranty against manufacturing defects only (Burn physical damage, burn due to coming in contact with water is not included in warranty)</span>
                            <br />
                            <span>4. Goods once sold will not be taken back</span>
                            <br />
                            <span>5. All disputes are subject to Delhi jurisdiction only</span>
                            <br />
                            <span>6. Shipping: dispatch by Road/Air & Freight amount will be extra</span>
                        </div>
                    </div>
                    {/* Total Counter */}
                    <div className="total-counter">
                        {/* Subtotal and Tax */}
                        <div className="bg-tertiary p-3">
                            <p className='flex-between mb-1'><span>Sub-Total</span><span className='fw-semibold'>Rs. {parseFloat(details.subTotalAmount).toFixed(2)}</span></p>
                            {details.taxCategory === "IN" ? <p className='flex-between mb-1'><span>IGST (18%)</span><span className='fw-semibold'>Rs. {parseFloat(details.subTotalAmount *18/100).toFixed(2)}</span></p> : 
                                <>
                                <p className='flex-between mb-1'><span>CGST (9%)</span><span className='fw-semibold'>Rs. {parseFloat(details.subTotalAmount *9/100).toFixed(2)}</span></p>
                                <p className='flex-between mb-0'><span>SGST (9%)</span><span className='fw-semibold'>Rs. {parseFloat(details.subTotalAmount *9/100).toFixed(2)}</span></p>
                                </>
                            }
                        </div>
                        <div className="bg-primary p-3">
                            <h6 className='fw-bold flex-between mb-0'><span>Grand Total</span><span>Rs. {Math.trunc(details.totalAmount)}</span></h6>
                        </div>
                    </div>
                </div>
                {/* Qr Code & Signature */}
                <div className="qrcode-signature">
                    <div className='d-flex'>
                        <div className='d-flex flex-column me-2'>
                            <div className="qr-div" style={{backgroundImage:"url('/assets/images/qrcode.png')"}}></div>
                            <button className='btn btn-tertiary'>Scan QR For Payment</button>
                        </div>
                        <div className='bank-details'>
                            <h5 className="fw-bold mb-0">Bank Details</h5>
                            <p><strong>Name:</strong> Geld Tech Sensor</p>
                            <p><strong>A/C No:</strong> 921020005156645</p>
                            <p><strong>Bank Name:</strong> AXIS BANK</p>
                            <p><strong>IFSC:</strong> UTIB0002750</p>
                            <p><strong>Branch:</strong> BURARI , DELHI-110084</p>
                            <p><strong>GSTIN No.:</strong> 07AMXPK0747R1Z2</p>
                            <img src="/assets/images/upi.png" alt="" />
                        </div>
                    </div>
                    <div className='signature-div'>
                        <img src="/assets/images/stamp.png" height={35} alt="" />
                        <img src="/assets/images/signatures/shubham.svg" height={50} alt="" />
                        <p>Authorised Signatory</p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default PrintHandler