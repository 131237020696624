import React from 'react'
import { Link } from 'react-router-dom'


const NotFound = () => {
return (
<section className='vh-100 vw-100 bg-primary d-flex align-items-center justify-content-center text-center text-white position-relative'
  style={{height: "100vh", width: "100vw", overflow: "hidden"}}>
  <div className="container-fluid">
    <h1 className='ls-tight font-bolder display-6 text-white mt-4 mb-3'>404 Not Found</h1>
    <p className='text-opacity-80'>Oops! We are not able to found your requested page</p>
    <Link className="btn btn-tertiary fw-bold" to="/">Go back to Home</Link>
  </div>
</section>
)
}

export default NotFound