import {createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import {composeWithDevTools} from "redux-devtools-extension"
import { billReducer } from "./reducers/billReducer"
import { userReducer } from "./reducers/userReducer"
import { performaInvoiceReducer } from "./reducers/performaInvoiceReducer"

const reducer = combineReducers({
    bills:billReducer,
    performaInvoices: performaInvoiceReducer,
    user: userReducer
})

let initialState={}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;