import React from 'react'
import Modal from "../utility/Modal"
import { useNavigate } from 'react-router-dom'

const ConvertHandler = ({id, type}) => {

  const navigate = useNavigate()

  const handelSubmit = (e) => {
    e.preventDefault();
    closeModal()
    type === "bill" ? navigate(`/performaInvoice/new?convert=true&convertId=${id}`) : navigate(`/bill/new?convert=true&convertId=${id}`)
  }

  const closeModal = () => {
    document.getElementById("closeConvertHandlerModal").click()
  }


  return (
    <section>
        <Modal targetName={"convertHandler"} modalSettings={"modal-lg"}>
            <div className='p-lg-5 p-1 text-center'>
                <h1>{type==="bill" ? "Create new PI with selected bill?" : "Convert PI To Tax Invoice?"}</h1>
                <div className="row flex-center">
                    <div className="col-12 col-lg-6">
                        <button className="btn btn-danger shadow w-100 fs-4 flex-center" onClick={closeModal}>No</button>
                    </div>
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                        <button className="btn btn-tertiary shadow w-100 fs-4 flex-center" onClick={handelSubmit}>Yes</button>
                    </div>
                </div>
            </div>
            <button className='d-none' data-bs-dismiss="modal" id="closeConvertHandlerModal">Close modal</button>
        </Modal>
    </section>
  )
}

export default ConvertHandler