import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import Modal from "../utility/Modal"
import Toaster from "../utility/Toaster"
import { deleteBill } from '../../redux/actions/billActions'
import { deletePerformaInvoice } from '../../redux/actions/performaInvoiceAction'

const DeleteHandler = ({id, type}) => {

    const dispatch = useDispatch()
    const [buttonLoading, setButtonLoading] = useState(false)
  
    // toaster states
    const [toaster, setToaster] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [toasterType, setToasterType] = useState("")

    const handelSubmit = (e) => {
      e.preventDefault();
      setButtonLoading(true)
      dispatch( type==="bill" ? deleteBill(id) : deletePerformaInvoice(id))
      .then(res=>{
        setButtonLoading(false)
        if (!res.success) {
            handleToaster(res.message, "error")
        } else {
            handleToaster("Deleted successfully", "success")
        }
        closeModal()
      })
    }
  
    const closeModal = () => {
      document.getElementById("closeDeleteHandlerModal").click()
    }
  
    const handleToaster = (message, icon) => {
      setToaster(true)
      setToasterMessage(message)
      setToasterType(icon)
    }
    

  return (
    <div>
        <Modal targetName={"deleteHandler"}>
            <div className='p-lg-5 p-1 text-center'>
                <h1>{type === "bill" ? "Delete Bill" : "Delete PI"}</h1>
                <div className="row flex-center">
                    <div className="col-12 col-lg-6">
                        <button className="btn btn-primary shadow w-100 fs-4 flex-center" onClick={closeModal}>Cancel</button>
                    </div>
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                        <button className="btn btn-danger shadow w-100 fs-4 flex-center" onClick={handelSubmit}>{buttonLoading ? <div className="spinner-border fs-6 button-spinner flex-center" role="status"><span className="visually-hidden">Loading...</span></div> : "Delete"}</button>
                    </div>
                </div>
            </div>
            <button className='d-none' data-bs-dismiss="modal" id="closeDeleteHandlerModal">Close modal</button>
        </Modal>
        <Toaster text={toasterMessage} toasterType={toasterType} toaster={toaster} setToaster={setToaster} />
    </div>
  )
}

export default DeleteHandler